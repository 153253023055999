<template>
  <collapse-card class="bg-white">
    <template #cardContent>
      <div>
        <base-directory-tree
          v-bind="filteredDirectoryTreeProps"
          :selected-node="selectedNode"
          @nodeSelected="updateSelectedNode($event)"
        />
      </div>
    </template>
  </collapse-card>
</template>

<script>
import { computed, defineAsyncComponent } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import useStyleGuideDirectory from '@/hooks/styleGuideDirectory.js';

export default {
    name: 'StyleGuideCard',

    components: {
        BaseDirectoryTree: defineAsyncComponent(() => import('@/components/generic-components/base-directory-tree/BaseDirectoryTree.vue')),
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue'))
    },

    props: {
        directoryTreeProps: {
            type: Object,
            default: () => {},
            description: 'props object base-directory tree(check base-directory-tree component for supported props)'
        }
    },

    setup (props, { slots }) {
        const router = useRouter();
        const route = useRoute();

        // select node logic
        const { selectedNode, handleSelectedNode } = useStyleGuideDirectory();
        const updateSelectedNode = (nodeData) => {
            if (route.name !== 'StyleGuide') {
                router.push({ name: 'StyleGuide' });
            }
            handleSelectedNode({ nodeData });
        };

        const filteredDirectoryTreeProps = computed(() => {
          if (!props.directoryTreeProps) return {}

          let tmp = { ...props.directoryTreeProps }
          tmp.directoryTreeData = tmp.directoryTreeData.filter(n=>n.children)

          return tmp
        })

        return {
            selectedNode,
            handleSelectedNode,
            updateSelectedNode,
            filteredDirectoryTreeProps
        };
    }
};
</script>
